import React from "react"
import ReactWOW from "react-wow"
import { Link, graphql } from "gatsby"
import PageBanner from "../components/Common/PageBanner"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"
import CarLoan from "../assets/images/car-loan/graphic-car-loans.svg"
import CarClarity from "../assets/images/car-loan/CarClarity.svg"
import FiveStar from "../assets/images/car-loan/stars-5.svg"
import trustpilot from "../assets/images/car-loan/trustpilot-logo-black.svg"
import { IconContext } from "@react-icons/all-files"
import { MdCheckCircle } from "@react-icons/all-files/md/MdCheckCircle"

const CarLoans = () => {
  return (
    <Layout>
      <SEO
        title="Car Loans | Oxygen - Home Loans Made Simple"
        description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
      />
      <Navbar />
      {/* <div className="car-loans-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="wr-title-group">
                <PageBanner
                  pageTitle="Get a better car loan.        
              Hassle free."
                  subHeading="Compare 30+ lenders in 60 seconds!"
                  pageSubTitle="Personalised repayments with no impact on your credit score."
                />
                <ReactWOW delay=".05s" animation="fadeInUp">
                  <div className="btn-box">
                    <a
                      target="__blank"
                      href="https://webapp.dev.oxygen.com.au/files/carClarity.html"
                      className="default-btn primary-btn btn-lg"
                    >
                      Get Started <span></span>
                    </a>
                  </div>
                </ReactWOW>
              </div>
            </div>
            <div className="col-lg-5 d-flex align-items-center justify-content-end">
              <img src={carLoan} alt="electricity scale" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="carloans-hero-banner">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8">
              <div className="main-banner-content">
                <h1>
                  Get a Better Car Loan. <br></br>Hassle Free.
                </h1>
                <h2 className="mt-3 mt-lg-4">
                  Compare 30+ lenders in 60 seconds!
                </h2>
                <p className="subtitle">
                  Personalised repayments with no impact on your credit score.
                </p>
                <ReactWOW delay=".05s" animation="fadeInUp">
                  <div className="btn-box pt-2 pt-lg-3">
                    <Link
                      to={`${process.env.GATSBY_BASE_URL}/General/CarClarity`}
                      className="default-btn primary-btn btn-lg mb-2 mr-0 mx-2"
                    >
                      Apply Now <span></span>
                    </Link>
                  </div>
                </ReactWOW>
                {/* <div className="img-car-clarity mt-2 mt-lg-3">
                <img  src={CarClarity} alt="car-clarity" />
              </div> */}
                <div className="wr-ownership">
                  <div className="row mx-auto">
                    <div className="car-clarity col-lg-4">
                      <div>
                        <label>Powered by</label>
                        <img src={CarClarity} alt="car-clarity" />
                      </div>
                    </div>
                    <div className="trust-pilot col-lg-8">
                      <label>Excellent</label>
                      <img src={FiveStar} alt="five-star" />
                      <img src={trustpilot} alt="trust-pilot" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="carloans-drawing-bg"> */}
        <ReactWOW delay=".05s" animation="fadeIn">
          <div className="wr-car-loan">
            <img src={CarLoan} alt="Car Loan" />
          </div>
        </ReactWOW>
        {/* </div> */}
        <div className="container mb-5 mt-2 mt-lg-0 marketing-copy-container">
          <div className="row">
            <div className="col-12 col-md-4 d-flex d-flex justify-content-center">
              <div className="">
                <IconContext.Provider
                  value={{ color: "", className: "icon-check" }}
                >
                  <MdCheckCircle />
                </IconContext.Provider>
                <span class="marketing-copy">Competitive Rates</span>
              </div>
            </div>

            <div className="col-12 col-md-4 d-flex d-flex justify-content-center pt-2 pt-md-0">
              <div className=" ">
                <IconContext.Provider
                  value={{ color: "", className: "icon-check" }}
                >
                  <MdCheckCircle />
                </IconContext.Provider>
                <span class="marketing-copy">
                  Personal and Business Car Loans
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4 d-flex d-flex justify-content-center pt-2 pt-md-0">
              <div className="">
                <IconContext.Provider
                  value={{ color: "", className: "icon-check" }}
                >
                  <MdCheckCircle />
                </IconContext.Provider>
                <span class="marketing-copy">Safe and Secure</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default CarLoans
